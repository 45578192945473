const MESSAGE_TYPES = {
  AM_I_ALIVE: "am_i_alive",
  FILE_UPDATE: "file_update",
  CURRENT_OPERATIONS: "current_op",
  NEW_CAMERA_IMAGE: "new_camera_image"
};

module.exports = {
  MESSAGE_TYPES
};
